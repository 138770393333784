import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useCalendarPermissions = function (user) {
    // calendar
    var hasPermissionCreateCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.MY_WORKTIME, MethodCode.CREATE);
    var hasPermissionUpdateCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.MY_WORKTIME, MethodCode.EDIT);
    var hasPermissionDeleteCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.MY_WORKTIME, MethodCode.DELETE);
    // PERMISSION FOR USER CALENDAR
    var hasPermissionEditUserCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.WORKTIME, MethodCode.EDIT);
    var hasPermissionViewUserCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.WORKTIME, MethodCode.VIEW);
    var hasPermissionDeleteUserCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.WORKTIME, MethodCode.DELETE);
    var hasPermissionCreateUserCalendar = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.WORKTIME, MethodCode.CREATE);
    return {
        hasPermissionCreateCalendar: hasPermissionCreateCalendar,
        hasPermissionUpdateCalendar: hasPermissionUpdateCalendar,
        hasPermissionDeleteCalendar: hasPermissionDeleteCalendar,
        hasPermissionEditUserCalendar: hasPermissionEditUserCalendar,
        hasPermissionViewUserCalendar: hasPermissionViewUserCalendar,
        hasPermissionDeleteUserCalendar: hasPermissionDeleteUserCalendar,
        hasPermissionCreateUserCalendar: hasPermissionCreateUserCalendar,
    };
};
export default useCalendarPermissions;
